.plans-section {
    background-color: #ffff;
    display: flex;
    justify-content: center;
    gap: 30px;
    padding: 30px;
}

.plan-card {
    background-color: white;
    width: 20%;
    min-width: 300px; /* Added min-width to prevent the card from becoming too narrow */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    transition: transform 300ms ease, box-shadow 300ms ease;
}

.plan-card:hover {
    transform: scale(1.1);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.title {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    color: #5e17eb;
}

.price {
    text-align: center;
    font-size: 18px;
    margin: 10px 0;
    font-weight: bold;
}

.description {
    text-align: left;
    max-height: 100px;
    overflow-y: auto;
}

.fa-check {
    color: #00bf63;
}

ul {
    list-style-type: none;
    text-align: left;
    padding: 20px;
}

ul li {
    margin-bottom: 5px;
    font-size: 14px;
}

.btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn {
    display: inline-block;
    width: 50%;
    padding: 10px 20px;
    text-decoration: none;
    color: white;
    border: none;
    background-color: #3498db;
    border-radius: 5px;
    transition: background-color 300ms ease;
    cursor: pointer;
}

.btn:hover {
    background-color: #2980b9;
}

@media (max-width: 768px) {
    .plans-section {
        flex-direction: column;
        align-items: center;
    }

    .plan-card {
        width: 70%; /* Adjust the width for medium screens */
        min-width: auto; /* Remove min-width for medium screens */
        margin-bottom: 20px; /* Add margin between cards */
    }

    .title {
        font-size: 24px; /* Adjust font size for medium screens */
    }

    .price {
        font-size: 16px; /* Adjust font size for medium screens */
    }

    .description {
        max-height: none; /* Allow full height for description on medium screens */
        overflow-y: visible; /* Disable vertical scrolling for description on medium screens */
    }

    .btn {
        width: 100%; /* Make the button full width for medium screens */
    }
}

@media (max-width: 480px) {
    .plan-card {
        width: 90%; /* Adjust the width for smaller screens */
    }

    .title {
        font-size: 20px; /* Adjust font size for smaller screens */
    }

    .price {
        font-size: 14px; /* Adjust font size for smaller screens */
    }
}
