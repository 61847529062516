:root {
 /* 🎨 PALETA DE COLORES MEJORADA */
  --primary-color: #1E3A8A;   /* Azul profundo: Profesional y confiable */
  --secondary-color: #22C55E; /* Verde vibrante: Modernidad y acción */
  --accent-color: #FACC15;    /* Amarillo vibrante: Resaltador amigable */
  --background-color: #F3F4F6; /* Gris claro: Sofisticación y limpieza */
  --light-blue: #A5B4FC;      /* Azul claro: Suavidad y confianza */
  --white: #FFFFFF;           /* Blanco puro: Limpieza y legibilidad */
  --dark-text: #1E293B;       /* Gris oscuro: Profesional y elegante */
  --light-text: #6B7280;      /* Gris medio: Neutro y moderno */

  /* 📐 Tipografía */
  --font-primary: "Montserrat", sans-serif;
  --font-secondary: "Lato", sans-serif;

  /* 🎭 Sombras y bordes */
  --box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  --border-radius: 10px;

  /* 🏷️ Espaciado */
  --spacing-small: 8px;
  --spacing-medium: 16px;
  --spacing-large: 32px;

  /* 📏 Anchos */
  --max-width: 1200px;
}

/* === RESETEO BÁSICO === */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-secondary);
  background: var(--background-color);
  color: var(--dark-text);
  line-height: 1.6;
}

/* === BOTONES === */
.button {
  display: inline-block;
  padding: var(--spacing-small) var(--spacing-medium);
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  border-radius: var(--border-radius);
  transition: all 0.3s ease-in-out;
}

.button-primary {
  background: var(--primary-color);
  color: var(--white);
}

.button-primary:hover {
  background: var(--accent-color);
  color: var(--primary-color);
}

.button-secondary {
  background: var(--secondary-color);
  color: var(--white);
}

.button-secondary:hover {
  background: var(--accent-color);
  color: var(--secondary-color);
}

/* === ENCABEZADOS === */
h1, h2, h3 {
  font-family: var(--font-primary);
}

h1 {
  font-size: 2.5rem;
  color: var(--primary-color);
}

h2 {
  font-size: 2rem;
  color: var(--secondary-color);
}

h3 {
  font-size: 1.5rem;
  color: var(--dark-text);
}

/* === HEADER GENERAL === */
.header {
  font-family: var(--font-primary);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  box-shadow: var(--box-shadow);
  transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  z-index: 1000;
  padding: 0 10px;
}

/* Cambia el color al hacer scroll */
.header-scroll {
  background: var(--white);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

/* === CONTENEDOR PRINCIPAL === */
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--spacing-medium);
}

/* === LOGO === */
.logo-container img {
  max-width: 160px;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.logo-container img:hover {
  transform: scale(1.05);
}

/* === NAVBAR (Menú principal) === */
.nav-menu {
  display: flex;
  align-items: center;
}

.nav-menu ul {
  display: flex;
  gap: var(--spacing-medium);
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-menu ul li {
  position: relative;
}

.nav-menu ul li a {
  text-decoration: none;
  color: var(--primary-color);
  font-size: 1rem;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
}

.nav-menu ul li a:hover {
  color: var(--secondary-color);
}

/* Cambia el color de los enlaces al hacer scroll */
.header-scroll .nav-menu ul li a {
  color: var(--primary-color);
}

.header-scroll .nav-menu ul li a:hover {
  color: var(--accent-color);
}

/* === BOTÓN MENÚ HAMBURGUESA (Móviles) === */
.button-menu {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 28px;
  color: var(--primary-color);
  transition: color 0.3s ease-in-out;
}

.button-menu .hamburger-menu {
  transition: transform 0.3s ease-in-out;
}

.menu-open .button-menu .hamburger-menu {
  transform: rotate(90deg);
}

.header-scroll .button-menu {
  color: var(--dark-text);
}

/* === MENÚ MÓVIL === */
@media (max-width: 768px) {
  .nav-menu {
    position: fixed;
    top: 0;
    left: -100%;
    width: 75%;
    height: 100vh;
    background: var(--white);
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
    padding: var(--spacing-large);
    transition: left 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .nav-menu.menu-open {
    left: 0;
  }

  .nav-menu ul {
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-medium);
  }

  .button-menu {
    display: block;
  }

  /* === Overlay para cerrar el menú en móviles === */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: none;
  }

  .menu-open .overlay {
    display: block;
  }
}


/* === BOTONES DE CONTACTO === */
.contact-link,
.whatsapp-link {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.contact-link i,
.whatsapp-link i {
  margin-right: var(--spacing-small);
  font-size: 18px;
}

.contact-link:hover {
  color: var(--primary-color);
}

.whatsapp-link {
  color: var(--secondary-color);
}

.whatsapp-link:hover {
  color: var(--accent-color);
}

/* === HERO SECTION MEJORADO CON FONDO CLARO === */
.hero-section {
  background: var(--background-color); /* Fondo claro */
  color: var(--dark-text); /* Texto oscuro para mejor legibilidad */
  text-align: center;
  padding: var(--spacing-large) 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Asegura que ocupe toda la pantalla */
  padding-top: calc(var(--spacing-large) + 80px); /* Agrega margen dinámico para el Header */
}

/* Asegurar que el Header sea fijo y no afecte la Hero Section */
.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

/* === CONTENIDO HERO === */
.hero-content {
  max-width: var(--max-width);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-large);
}

/* === TEXTO HERO === */
.hero-text {
  flex: 1;
  text-align: left;
}

.hero-text h1 {
  font-size: 3rem;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: var(--spacing-medium);
}

.hero-text .highlight {
  color: var(--secondary-color);
}

.hero-text h2 {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6;
  color: var(--light-text);
  opacity: 0.9;
  margin-bottom: var(--spacing-medium);
}

.hero-text .trust {
  font-weight: bold;
  color: var(--accent-color);
}

/* === BOTÓN CTA MEJORADO === */
.cta-button {
  display: inline-block;
  background-color: var(--primary-color);
  color: var(--white);
  font-weight: bold;
  text-decoration: none;
  padding: 14px 28px;
  border-radius: var(--border-radius);
  font-size: 1.2rem;
  box-shadow: var(--box-shadow);
  transition: all 0.3s ease-in-out;
}

.cta-button:hover {
  background-color: var(--secondary-color);
  color: var(--white);
  transform: scale(1.05);
}

/* === PRECIO MEJORADO === */
.pricing {
  margin-top: var(--spacing-medium);
  font-size: 1.3rem;
  font-weight: bold;
  color: var(--primary-color);
  opacity: 0.9;
}

/* === IMAGEN HERO MEJORADA === */
.hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
}

.hero-image img {
  max-width: 500px;
  width: 100%;
  height: auto;

}

/* === RESPONSIVE DESIGN === */
@media (max-width: 1024px) {
  .hero-content {
    flex-direction: column;
    text-align: center;
  }

  .hero-text {
    text-align: center;
  }

  .hero-image img {
    max-width: 320px;
  }
}

@media (max-width: 768px) {
  .hero-text h1 {
    font-size: 2.4rem;
  }

  .hero-text h2 {
    font-size: 1.4rem;
  }

  .cta-button {
    font-size: 1.1rem;
    padding: 12px 24px;
  }
}
/* === SECCIÓN DE PLANES === */
.plans-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--spacing-large);
  padding: var(--spacing-large) 20px;
  background: var(--background-color);
}

/* === TARJETA DE PLAN === */
.plan-card {
  background: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: var(--spacing-medium);
  width: 300px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.plan-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.15);
}

/* === CABECERA DEL PLAN === */
.plan-header {
  padding-bottom: var(--spacing-medium);
  border-bottom: 2px solid var(--primary-color);
}

.plan-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary-color);
}

.plan-price {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--secondary-color);
  margin-top: var(--spacing-small);
}

/* === DESCRIPCIÓN DEL PLAN === */
.plan-description {
  font-size: 1rem;
  color: var(--light-text);
  margin-top: var(--spacing-medium);
}

/* === LISTA DE BENEFICIOS === */
.plan-features {
  list-style: none;
  padding: 0;
  margin-top: var(--spacing-medium);
}

.plan-feature {
  font-size: 0.95rem;
  color: var(--dark-text);
  padding: var(--spacing-small);
  display: flex;
  align-items: center;
  gap: var(--spacing-small);
}

.check-icon {
  color: var(--secondary-color);
  font-size: 1rem;
}

/* === BOTÓN DE INFORMACIÓN === */
.plan-btn-container {
  margin-top: var(--spacing-medium);
}

.plan-btn {
  background: var(--primary-color);
  color: var(--white);
  padding: var(--spacing-small) var(--spacing-medium);
  border: none;
  border-radius: var(--border-radius);
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.plan-btn:hover {
  background: var(--secondary-color);
  color: var(--white);
  transform: scale(1.05);
}

/* === RESPONSIVE DESIGN === */
@media (max-width: 1024px) {
  .plans-section {
    flex-direction: column;
    align-items: center;
  }

  .plan-card {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .plan-title {
    font-size: 1.4rem;
  }

  .plan-price {
    font-size: 1.1rem;
  }

  .plan-description {
    font-size: 0.95rem;
  }

  .plan-btn {
    font-size: 0.95rem;
  }
}

/* === SECCIÓN DE EDUCACIÓN CONTABLE Y FISCAL === */
.education-section {
  background: var(--background-color);
  padding: var(--spacing-large) 20px;
  text-align: center;
}

.education-content {
  max-width: 800px;
  margin: auto;
}

.education-title {
  font-size: 2rem;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: var(--spacing-medium);
}

.education-text {
  font-size: 1.2rem;
  color: var(--light-text);
  line-height: 1.6;
  margin-bottom: var(--spacing-medium);
}

.education-btn {
  display: inline-block;
  background-color: var(--primary-color);
  color: var(--white);
  font-weight: bold;
  text-decoration: none;
  padding: 14px 28px;
  border-radius: var(--border-radius);
  font-size: 1.2rem;
  box-shadow: var(--box-shadow);
  transition: all 0.3s ease-in-out;
}

.education-btn:hover {
  background-color: var(--secondary-color);
  color: var(--white);
  transform: scale(1.05);
}

/* === RESPONSIVE DESIGN === */
@media (max-width: 768px) {
  .education-title {
    font-size: 1.8rem;
  }
  .education-text {
    font-size: 1rem;
  }
  .education-btn {
    font-size: 1rem;
    padding: 12px 24px;
  }
}

/* === FOOTER ESTILIZADO === */
.footer {
  background: var(--dark-text);
  color: var(--white);
  padding: var(--spacing-large) 20px;
  text-align: center;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: auto;
  padding-bottom: var(--spacing-medium);
}

/* === LOGO Y DESCRIPCIÓN === */
.footer-brand {
  flex: 1;
  text-align: left;
}

.footer-logo {
  max-width: 150px;
  margin-bottom: var(--spacing-small);
}

.footer-description {
  font-size: 1rem;
  color: var(--white);
  padding: 0 16px ;
}

/* === ENLACES RÁPIDOS === */
.footer-links-container {
  flex: 1;
  text-align: left;
}

.footer-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--accent-color);
  margin-bottom: var(--spacing-small);
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: var(--spacing-small);
}

.footer-links a {
  color: var(--white);
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.footer-links a:hover {
  color: var(--accent-color);
}

/* === REDES SOCIALES === */
.footer-social {
  flex: 1;
  text-align: left;
}

.social-icons {
  list-style: none;
  padding: 0;
  display: flex;
  gap: var(--spacing-small);
}

.social-icons li a {
  color: var(--white);
  font-size: 1.5rem;
  transition: color 0.3s ease-in-out;
}

.social-icons li a:hover {
  color: var(--accent-color);
}

/* === DERECHOS Y DESARROLLADOR === */
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--light-text);
  padding-top: var(--spacing-small);
  margin-top: var(--spacing-medium);
}

.rights {
  font-size: 0.9rem;
  color: var(--light-text);
}

.isasoft-container {
  display: flex;
  align-items: center;
}

.isasoftlogo {
  max-width: 120px;
  transition: transform 0.3s ease-in-out;
}

.isasoftlogo:hover {
  transform: scale(1.1);
}

/* === RESPONSIVE DESIGN === */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    text-align: center;
  }
  .footer-brand, .footer-links-container, .footer-social {
    text-align: center;
    margin-bottom: var(--spacing-medium);
  }
  .footer-bottom {
    flex-direction: column;
    text-align: center;
  }
}

/* === SECCIÓN ABOUT === */
.about-section {
  background: var(--background-color);
  padding: calc(var(--spacing-large) + 80px) 20px var(--spacing-large); /* Ajusta espacio para el Navbar fijo */
  text-align: center;
}

.about-container {
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-large);
  flex-direction: row-reverse; /* Imagen a la derecha, texto a la izquierda */
}

/* === IMAGEN DE LA CONTADORA === */
.about-image {
  flex: 1;
  display: flex;
  justify-content: center;
}

.about-image img {
  max-width: 350px;
  width: 100%;
  height: auto;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

/* === CONTENIDO DEL ABOUT === */
.about-content {
  flex: 2;
  text-align: left;
}

.about-title {
  font-size: 2rem;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: var(--spacing-medium);
}

.about-text {
  font-size: 1.2rem;
  color: var(--light-text);
  line-height: 1.6;
  margin-bottom: var(--spacing-medium);
}

.about-subtitle {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--secondary-color);
  margin-bottom: var(--spacing-small);
}

.about-list {
  list-style: none;
  padding: 0;
}

.about-list li {
  font-size: 1.1rem;
  color: var(--dark-text);
  display: flex;
  align-items: center;
  gap: var(--spacing-small);
  margin-bottom: var(--spacing-small);
}

.about-list i {
  color: var(--secondary-color);
  font-size: 1.2rem;
}

/* === SEGUNDA SECCIÓN (COMPROMISO Y ÉTICA) === */
.about-second-section {
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-large);
  margin-top: var(--spacing-large);
}

.about-second-content {
  flex: 2;
  text-align: left;
}

.about-second-text {
  font-size: 1.2rem;
  color: var(--light-text);
  line-height: 1.6;
}

.about-second-image {
  flex: 1;
  display: flex;
  justify-content: center;
}

.about-second-image img {
  max-width: 350px;
  width: 100%;
  height: auto;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

/* === RESPONSIVE DESIGN === */
@media (max-width: 1024px) {
  .about-container, .about-second-section {
    flex-direction: column;
    text-align: center;
  }

  .about-content, .about-second-content {
    text-align: center;
  }

  .about-image, .about-second-image {
    order: -1; /* Imagen arriba en pantallas pequeñas */
  }
}

@media (max-width: 768px) {
  .about-title {
    font-size: 1.8rem;
  }

  .about-text, .about-second-text {
    font-size: 1rem;
  }

  .about-subtitle {
    font-size: 1.3rem;
  }

  .about-list li {
    font-size: 1rem;
  }
}

/* === SECCIÓN PRINCIPAL === */
.services-screen {
  background: var(--background-color);
  padding-top: calc(var(--spacing-large) + 80px); /* Ajuste por el Navbar fijo */
}

/* === SECCIÓN DE SERVICIOS === */
.services-section {
  text-align: center;
  padding: var(--spacing-large) 20px;
}

.services-title {
  font-size: 2rem;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: var(--spacing-medium);
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--spacing-large);
  max-width: 1200px;
  margin: auto;
}

.service-card {
  background: var(--white);
  padding: var(--spacing-medium);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  width: 300px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.15);
}

.service-icon {
  font-size: 2.5rem;
  color: var(--secondary-color);
  margin-bottom: var(--spacing-small);
}

.service-title {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--dark-text);
  margin-bottom: var(--spacing-small);
}

.service-description {
  font-size: 1rem;
  color: var(--light-text);
  line-height: 1.6;
}

/* === SECCIÓN DE PLANES === */
.plans-section {
  text-align: center;
  padding: var(--spacing-large) 20px;
  background: var(--light-blue);
}

.plans-title {
  font-size: 2rem;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: var(--spacing-medium);
}

/* === RESPONSIVE DESIGN === */
@media (max-width: 1024px) {
  .services-container {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .services-title, .plans-title {
    font-size: 1.8rem;
  }

  .service-title {
    font-size: 1.3rem;
  }

  .service-description {
    font-size: 0.95rem;
  }
}

/* === ESTILOS GENERALES DEL BLOG === */
.blog-container {
  background: var(--background-color);
  padding-top: calc(var(--spacing-large) + 80px); /* Ajuste por Navbar fijo */
  text-align: center;
}

/* === ENCABEZADO DEL BLOG === */
.blog-header {
  background: linear-gradient(135deg, var(--primary-color) 30%, var(--light-blue) 100%);
  color: var(--white);
  padding: var(--spacing-large) 20px;
  text-align: center;
  border-radius: var(--border-radius);
}

.blog-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--white);
  margin-bottom: var(--spacing-small);
}

.blog-subtitle {
  font-size: 1.2rem;
  opacity: 0.9;
}

/* === SECCIÓN DE POSTS === */
.blog-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--spacing-large);
  max-width: 1200px;
  margin: auto;
  padding: var(--spacing-large) 20px;
}

/* === TARJETAS DE BLOG === */
.blog-card {
  background: var(--white);
  padding: var(--spacing-medium);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  width: 320px;
  text-align: left;
  transition: transform 0.3s ease-in-out;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.15);
}

.blog-card img {
  width: 100%;
  border-radius: var(--border-radius);
}

.blog-card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary-color);
  margin-top: var(--spacing-small);
}

.blog-card-excerpt {
  font-size: 1rem;
  color: var(--light-text);
  line-height: 1.6;
  margin-bottom: var(--spacing-medium);
}

.blog-card-link {
  color: var(--secondary-color);
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.blog-card-link:hover {
  color: var(--accent-color);
}

/* === RESPONSIVE DESIGN === */
@media (max-width: 1024px) {
  .blog-content {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .blog-title {
    font-size: 2rem;
  }

  .blog-subtitle {
    font-size: 1rem;
  }

  .blog-card-title {
    font-size: 1.3rem;
  }
}


/* === ESTILOS GENERALES DEL ARTÍCULO === */
.article-container {
  background: var(--background-color);
  padding-top: calc(var(--spacing-large) + 80px); /* Ajuste por Navbar fijo */
  display: flex;
  justify-content: center;
  padding-bottom: var(--spacing-large);
}

.article-content {
  max-width: 800px;
  background: var(--white);
  padding: var(--spacing-large);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  text-align: left;
}

/* === ENCABEZADO DEL ARTÍCULO === */
.article-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: var(--spacing-medium);
}

.article-image {
  width: 100%;
  border-radius: var(--border-radius);
  margin-bottom: var(--spacing-medium);
}

.article-description {
  font-size: 1.3rem;
  font-weight: bold;
  color: var(--dark-text);
  margin-bottom: var(--spacing-small);
}

.article-date {
  font-size: 1rem;
  color: var(--light-text);
  margin-bottom: var(--spacing-medium);
  display: block;
}

/* === CUERPO DEL ARTÍCULO === */
.article-body {
  font-size: 1.1rem;
  color: var(--dark-text);
  line-height: 1.8;
  margin-bottom: var(--spacing-medium);
}

/* === AUTOR DEL ARTÍCULO === */
.article-author {
  font-size: 1rem;
  color: var(--light-text);
  font-style: italic;
  display: block;
  margin-bottom: var(--spacing-medium);
}

/* === BOTÓN VOLVER === */
.article-footer {
  text-align: center;
}

.back-button {
  display: inline-block;
  background-color: var(--secondary-color);
  color: var(--white);
  font-weight: bold;
  text-decoration: none;
  padding: 12px 24px;
  border-radius: var(--border-radius);
  font-size: 1.2rem;
  box-shadow: var(--box-shadow);
  transition: all 0.3s ease-in-out;
}

.back-button:hover {
  background-color: var(--accent-color);
  color: var(--primary-color);
  transform: scale(1.05);
}

/* === RESPONSIVE DESIGN === */
@media (max-width: 768px) {
  .article-content {
    padding: var(--spacing-medium);
  }

  .article-title {
    font-size: 2rem;
  }

  .article-description {
    font-size: 1.2rem;
  }

  .article-body {
    font-size: 1rem;
  }
}
