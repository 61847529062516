/* === SECCIÓN DE EMPRESAS QUE HAN CONFIADO EN NOSOTROS === */
.trusted-companies {
    background: var(--background-color);
    padding: var(--spacing-large) 20px;
    text-align: center;
  }
  
  .trusted-title {
    font-size: 2rem;
    font-weight: bold;
    color: var(--primary-color);
    margin-bottom: var(--spacing-medium);
  }
  
  /* === ESTILOS DEL SLIDER === */
  .slick-slider {
    max-width: 80%;
    margin: auto;
  }
  
  .slick-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  
  .company-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--spacing-small);
  }
  
  .company-logo {
    max-width: 120px;
    height: auto;
    transition: transform 0.3s ease-in-out;
    filter: grayscale(100%);
  }
  
  .company-logo:hover {
    transform: scale(1.1);
    filter: grayscale(0%);
  }
  
  /* === RESPONSIVE DESIGN === */
  @media (max-width: 1024px) {
    .slick-slider {
      max-width: 90%;
    }
    .company-logo {
      max-width: 100px;
    }
  }
  
  @media (max-width: 768px) {
    .trusted-title {
      font-size: 1.8rem;
    }
    .company-logo {
      max-width: 80px;
    }
  }